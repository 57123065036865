export default {
  address: '台南市安南區環館路358巷2號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14683.644421308198!2d120.2364888!3d23.0637204!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1c3a1f04f9d9984a!2z5ZyL5o-a57-h57-g5qOu5p6X!5e0!3m2!1szh-TW!2stw!4v1568684298341!5m2!1szh-TW!2stw',
  phone: '06-3966565',
  fbLink: 'https://www.facebook.com/jadeforestvilla/',
  fbMessage: 'https://m.me/jadeforestvilla',
  googleLink: 'https://goo.gl/maps/8yUfkfkP87hLqZDk7',
  caseName: '幸福大院',

  houseInfos: [
    ['投資興建', '威力國際開發股份有限公司'],
    ['全案指導', '國揚實業股份有限公司'],
    ['建築規劃', '元宏聯合建築師事務所'],
    ['景觀規劃', '老圃造園工程股份有限公司'],
    ['燈光設計', '日本Sirius Lighting Office'],
    ['環控設計', '國立成功大學 祐生環控研究中心'],
    ['結構設計', '凱巨工程顧問公司'],
    ['會館設計', '橙田室內裝修設計工程有限公司']
  ],

  gtmCode: ['5RXWK3R'], // 可放置多個
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
