export default [
  { name: '森林大境', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '' },
  { name: '幸福大院', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', offset: -120 },
  { name: '養生莊園', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '', offset: -120 },
  { name: '獨立會館', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '' },
  { name: '建築團隊', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '' },
  { name: '生活秘境', imgSrc: '', subTitle: '', section: 'section12', svgSrc: '' },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '' },
]
// 平面圖集 (連結到：平面圖)?? (先不處理)
